<template>
  <div class="full-page-centered-wrapper">
    <div class="login-container">
      <h2 class="logo">
        <img
          class="logo"
          alt="Tuck Trucks Logo"
          :src="
            this.getCdnFile('/images/logos/tuck-trucks/tt-logo-black.png')
          " />
      </h2>
      <form @submit.prevent="submit">
        <div class="input-wrapper gap_1">
          <label for="username">Email Address</label>
          <input
            v-model="loginForm.username"
            id="username"
            name="username"
            type="email"
            required />
        </div>
        <div class="input-wrapper gap_1">
          <label for="password">Password</label>
          <div class="password-field full-width">
            <input
              v-model="loginForm.password"
              id="password"
              name="password"
              :type="!showPassword ? 'password' : 'text'"
              required />
            <button
              :onclick="togglePasswordVisibilty"
              type="button"
              class="icon-only small inline switch-visibility">
              <span
                class="material-icons material-icons-round"
                v-if="!showPassword">
                <Icon :path="mdiEyeOff" :size="18" />
              </span>
              <span
                class="material-icons material-icons-round"
                v-if="showPassword">
                <Icon :path="mdiEye" :size="18" />
              </span>
            </button>
          </div>
        </div>

        <button type="submit" class="full" :disabled="loading">
          <LoadingSpinner v-if="loading" />
          <span class="sign-in" v-else>Sign in</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
  import { useToast } from "vue-toastification";
  import { mapActions } from "vuex";
  import store from "@/store";
  import router from "../router";
  import { mdiEyeOff, mdiEye } from "@mdi/js";

  export default {
    data() {
      return {
        loginForm: {
          username: "",
          password: "",
        },
        label: "Sign in",
        loading: false,
        showPassword: false,
        mdiEyeOff,
        mdiEye,
      };
    },
    methods: {
      ...mapActions({
        login: "apiPrivate/login",
      }),

      togglePasswordVisibilty() {
        this.showPassword = !this.showPassword;
      },

      async submit() {
        this.loading = true;
        this.label = "";

        try {
          let loginResponse = await this.login(this.loginForm);

          if (loginResponse.status != 200) {
            throw new Error("Login failed.");
          }

          // LOOK: Hard coded for Harwell, needs to use the claims from the above.
          // TODO: Upgrade this after the login system from admin is finished and ported over.
          store.dispatch("today/downloadTodaysEvent");

          router.push("/");
        } catch (error) {
          window.log.log(error);
          this.loading = false;

          const toast = useToast();
          toast.error("Login failed.");
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .login-container {
    max-width: $login_form_max_width;
    min-width: $login_form_min_width;
    width: $login_form_width;
    padding: 1rem 2rem 3rem;
    border-radius: $card_radius;
    box-shadow: $login_form_box_shadow;
  }
  h2.logo {
    margin: 2rem 0;
    img {
      min-width: $login_logo_img_min_width;
      max-width: $login_logo_img_max_width;
      width: $login_logo_img_width;
      margin: 0 auto;
      display: block;
      height: auto;
    }
  }
  .password-field {
    position: relative;
    input[name="password"] {
      width: 100%;
      padding-right: 50px;
    }
    button.switch-visibility {
      position: absolute;
      right: 0;
      color: #aaa;
      z-index: 4;
      width: 40px;
      height: 38px;
    }
  }
  .input-wrapper label {
    width: auto;
  }
</style>
